// Import our Bootstrap extensions
// Import all plugins
import * as bootstrap from 'bootstrap';

import Alpine from 'alpinejs';
import persist from '@alpinejs/persist'
import Prism from 'prismjs';
import './vendor';


window.Alpine = Alpine;
Alpine.plugin(persist);

document.addEventListener('alpine:init', () => {
    Alpine.data('contentboxcmsData', function () {
        return {
            isDark: this.$persist(false).as('contentboxcms-darkmode'),
            isOpenMenu: false,
            getCustomFieldValue(arr, name) {
                return arr.reduce((acc, cur) => {
                    if (cur.key == name) {
                        acc = cur.value;
                    }
                    return acc;
                }, "");
            },

        }
    })
})

Alpine.start();
